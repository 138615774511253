import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import StateContext from "../../helpers/ContextState";
import { PostAsync, format, Inputs } from "../../helpers/Functions";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Input from "../../components/InputSimple";
import Select from "../../components/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faCheck,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import Config from "../../helpers/Config";

const App = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const context = React.useContext(StateContext);
  const params = useParams();
  const [data, setData] = useState([]);
  const [btn, setBtn] = useState(false);
  const [paying, setPaying] = useState(false);
  const [btnPagar, setBtnPagar] = useState([]);
  const [proveedor, setProveedor] = useState({});
  const [totalLineas, setTotalLineas] = useState([]);
  const [totalLineasPeso, setTotalLineasPeso] = useState([]);
  const [totalLineasTotal, setTotalLineasTotal] = useState([]);
  const [totalReteIva, setTotalReteIva] = useState([]);
  const [totalGeneral, setTotalGeneral] = useState({
    peso: 0,
    cantidad: 0,
    total: 0,
  });
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [retentions, setRetentions] = useState([]);
  const [tipoUsuario, setTipoUsuario] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [subTotalFooter, setSubtotalFooter] = useState(0);
  const [totalGeneralFooter, setTotalGeneralFooter] = useState(0);
  const [totalIva, setTotalIva] = useState(0);
  const [semestre, setSemestre] = useState("");
  const [observacion, setObservacion] = useState("");
  const [formValidated, setFormValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [productoFilter, setProductoFilter] = useState("");
  const [marcaFilter, setMarcaFilter] = useState("");
  const [semanaFilter, setSemanaFilter] = useState("");
  const [fechaInicioFiltro, setFechaInicioFiltro] = useState("");
  const [fechaFinFiltro, setFechaFinFiltro] = useState("");
  const [dataFiltrada, setDataFiltrada] = useState([]);
  const [opInventarioItemsFilter, setOpInventarioItemsFilter] = useState("");
  const [reteiva, setReteiva] = useState(0);
  const [reteica, setReteica] = useState(0);
  const [retefuente, setRetefuente] = useState(0);
  const [baseRetefuente, setBaseRetefuente] = useState(0);
  const [totalPagar, setTotalPagar] = useState(0);
  const history = useHistory();
  let filter = "";

  useEffect(() => {
    setDataFiltrada(data);
  }, [data]);

  const basicInputs = () => {
    PostAsync(
      "Inventario",
      "ListadoCompras",
      { inTrash: 1, fecha: "0000-00-00", limit: 0, uid: params.id, start: 0 },
      context
    ).then((response) => {
      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas);
      }

      if (response.data && response.data.marcas) {
        console.log("response.data.marcas",response.data.marcas);
        setMarcas(response.data.marcas);
      }

      if (response.data && response.data.productos) {
        setProductos(response.data.productos);
      }
    });
  };

  useEffect(() => {
    const objetosPrincipalesFiltrados = data.reduce((acumulador, objeto) => {
      const itemsFiltrados = objeto.items.filter((item) => {
        const fechaCompra = new Date(item.fecha_compra);
        const fechaInicioValida =
          !fechaInicioFiltro || fechaCompra >= new Date(fechaInicioFiltro);
        const fechaFinValida =
          !fechaFinFiltro || fechaCompra <= new Date(fechaFinFiltro);
        const semanaValida = !semanaFilter || item.semana === semanaFilter;

        const marcaAntesPrimerGuion =
          !marcaFilter || item.marca_id === marcaFilter;

        const opInventarioItemsValido =
          !opInventarioItemsFilter ||
          item.op_inventario_items_id === opInventarioItemsFilter;

        return (
          fechaInicioValida &&
          fechaFinValida &&
          semanaValida &&
          marcaAntesPrimerGuion &&
          opInventarioItemsValido
        );
      });

      if (itemsFiltrados.length > 0) {
        acumulador.push({
          ...objeto,
          items: itemsFiltrados,
        });
      }

      return acumulador;
    }, []);

    setDataFiltrada(objetosPrincipalesFiltrados);
  }, [
    fechaInicioFiltro,
    fechaFinFiltro,
    semanaFilter,
    marcaFilter,
    opInventarioItemsFilter,
    data,
  ]);

  useEffect(() => {
    if (params.id) {
      getInit();
      basicInputs();
    }
  }, [productoFilter]);

  const getInit = () => {
    setData([]);

    PostAsync(
      "Pagos",
      "ListaPagosDetalle",
      { ...inputs, id: params.id },
      context
    ).then((response) => {
      // console.log(response);
      setTipoUsuario(response.tipoUsuario);
      setRetentions(response.impuestos);

      if (response.proveedor) {
        setProveedor(response.proveedor);
      }

      if (response.data && Object.entries(response.data).length > 0) {
        setTaxes(response.system);

        let data_ = [];
        let totalGeneral_ = {};
        let totalLineas_ = [];

        let totalLineasPeso_ = [];

        let totalLineasTotal_ = [];

        let btnPagar_ = [];
        let btnPagar__ = [];
        let activeOnBtn__ = false;

        let totalIva = 0;
        let totalGeneralFooter = 0;
        // let totalLineas = 0
        // let totalLineasPeso =0
        // let totalLineasTotal = 0

        Object.entries(response.data).map((row, key) => {
          // console.log(response.data);
          data_.push({ label: row[0], items: row[1] });

          totalLineas_[row[0]] = 0;
          totalLineasPeso_[row[0]] = 0;
          totalLineasTotal_[row[0]] = 0;
          btnPagar_ = [];

          row[1].map((row2, key2) => {
            // console.log(row2);
            // // totalLineas += parseFloat(row2.cantidad)
            // // totalLineasPeso += parseFloat(row2.peso)
            // // totalLineasTotal += parseFloat(row2.total_restante)
            // totalLineas_ += parseFloat(row2.cantidad);
            // totalLineasPeso_ += parseFloat(row2.peso);
            // totalLineasTotal_ += parseFloat(row2.total_restante);
            // setSubtotalFooter(row2.total_restante);
            btnPagar_.push({
              ...row2,
              uid: row2.token,
              key: key2,
              pagar: false,
            });
            // activeOnBtn__ = true;
            // // if()
            // totalIva += parseInt(row2.iva_real);
            // totalGeneralFooter += parseFloat(row2.total_restante);
          });

          // console.log(btnPagar_);
          // setTotalLineas(totalLineas)
          // setTotalLineasPeso(totalLineasPeso)
          // setTotalLineasTotal(totalLineasTotal)

          setTotalIva(totalIva);
          setSubtotalFooter(totalGeneralFooter);
          setTotalGeneralFooter(totalGeneralFooter);
          btnPagar__.push(btnPagar_);
        });

        setBtn(activeOnBtn__);
        setBtnPagar(btnPagar__);
        setTotalLineas(totalLineas_);
        setTotalLineasPeso(totalLineasPeso_);
        setTotalLineasTotal(totalLineasTotal_);
        setData(data_);
      } else {
        history.push("/Pagos/ListaEfectados");
      }
    });
    if (params.fecha_inicio && params.fecha_fin) {
      setFechaInicioFiltro(params.fecha_inicio);
      setFechaFinFiltro(params.fecha_fin);
    }
  };

  const selection = (label, id) => {
    const key = data.findIndex((pro) => pro.label === label);
    const key2 = data[key].items.findIndex(
      (reg) => reg.op_inventario_items_inventario_id === id
    );

    if (btnPagar[key] && btnPagar[key][key2]) {
      let btnPagar_ = [...btnPagar];

      btnPagar_[key][key2].pagar = btnPagar_[key][key2].pagar ? false : true;

      setBtnPagar(btnPagar_);
      // console.log(btnPagar);
      let totalGeneralFooter = 0;
      // let totalLineas = [];
      // let totalLineasPeso =0
      // let totalLineasTotal = 0
      let totalIva = 0;
      let allCheck = true;
      console.log(btnPagar_);
      // console.log("taxes",taxes);
      totalLineas[label] = 0;
      totalLineasPeso[label] = 0;
      totalLineasTotal[label] = 0;
      btnPagar_.map((row, key) => {
        row.map((row2, key2) => {
          if (row2.pagar === true) {
            if (row2.producto === label) {
              totalLineas[label] += parseInt(row2.cantidad);
              totalLineasPeso[label] += parseFloat(row2.peso);
              totalLineasTotal[label] += parseFloat(row2.total_restante);
            }
            totalGeneralFooter += parseFloat(row2.total_restante);
            totalIva += parseFloat(row2.iva_real);
            // console.log( allCheck);
          } else {
            allCheck = false;
          }
          return row2;
        });
        return row;
      });
      setSelectAll(allCheck);
      setTotalGeneralFooter(totalGeneralFooter);
      setTotalIva(retentions.aplica_iva == 1 ? totalIva : 0);
      setTotalLineas(totalLineas);
      setTotalLineasPeso(totalLineasPeso);
      setTotalLineasTotal(totalLineasTotal);
      setReteiva(
        totalGeneralFooter > taxes.base && retentions.aplica_reteiva
          ? totalGeneralFooter * parseFloat(taxes.reteiva)
          : 0
      );
      setReteica(
        totalGeneralFooter > taxes.base_reteica && retentions.aplica_reteica
          ? totalGeneralFooter * parseFloat(taxes.reteica)
          : 0
      );
      
      setRetefuente(
        totalGeneralFooter > taxes.base_retefuente && retentions.aplica_retefuente == 1
          ? totalGeneralFooter * parseFloat(taxes.retefuente)
          : 0
      );
      setTotalPagar(
        totalGeneralFooter +
        totalIva -
        (retentions.aplica_reteiva && totalGeneralFooter > taxes.base
          ? totalGeneralFooter * parseFloat(taxes.reteiva)
            : 0) -
            (retentions.aplica_reteica && totalGeneralFooter > taxes.base_reteica
              ? totalGeneralFooter * parseFloat(taxes.reteica)
              : 0) -
              retefuente
            );
            let data_ = [...data];
            if (data_[key] && data_[key].items && data_[key].items[key2]) {
              // console.log(data_[key].items[key2].pagar);
              data_[key].items[key2].pagar =
              data_[key].items[key2].pagar === undefined ||
              data_[key].items[key2].pagar === false
              ? true
              : false;
            }
            verifyStatus(btnPagar_);
          }
        };
        console.log("lollllllllllllll",totalGeneralFooter > taxes.base_retefuente && retentions.aplica_retefuente == 1
          ? totalGeneralFooter * parseFloat(taxes.retefuente)
          : 0)

  // console.log("taxes.retefuente", taxes.retefuente * );

  // console.log("comparación", totalGeneralFooter > taxes.retefuente)
  const handleCloseCross = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    !checkActive() && setShowModal(false);
  };

  const handleShow = () => {
    checkActive() && setShowModal(true);
  };

  const checkActive = () => {
    const boolean = data.find((search) =>
      search.items.find((search2) => search2.pagar === true)
    );
    return boolean !== undefined ? true : false;
  };

  const verifyStatus = (data) => {
    const result = data.find((search) =>
      search.find((search2) => search2.pagar === true)
    );
    if (result) {
      setBtn(true);
    } else {
      setBtn(false);
    }
  };

  const handleDelete = (filter) => {
    if (filter === "producto") {
      setProductoFilter("");
      setOpInventarioItemsFilter(null);
      delete inputs.producto_id;
      setInputs({ ...inputs });
      Array.from(document.querySelectorAll("#Producto")).forEach(
        (select) => (select.value = "")
      );
    } else if (filter === "marca") {
      setMarcaFilter("");
      delete inputs.marca_id;
      setInputs({ ...inputs });
      Array.from(document.querySelectorAll("#Marca")).forEach(
        (select) => (select.value = "")
      );
    } else if (filter === "semana") {
      setSemanaFilter("");
      delete inputs.semana;
      setInputs({ ...inputs });
      Array.from(document.querySelectorAll("#Semana")).forEach(
        (select) => (select.value = "")
      );
    }
  };

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs);

    if (e.target.name === "producto_id") {
      let index = e.target.selectedIndex;
      let producto = e.target.options[index].text;
      // console.log(e.target);
      setOpInventarioItemsFilter(e.target.value);
    } else if (e.target.name === "marca_id") {
      let index = e.target.selectedIndex;
      let marca = e.target.value;
      setMarcaFilter(marca);
    } else if (e.target.name === "semana") {
      let index = e.target.selectedIndex;
      let semana = e.target.options[index].text;
      setSemanaFilter(semana);
    } else if (e.target.name === "fecha_inicio") {
      setFechaInicioFiltro(e.target.value);
    } else if (e.target.name === "fecha_fin") {
      setFechaFinFiltro(e.target.value);
    }
  };

  const handleSubmitPago = (event) => {
    setPaying(true)
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    form.checkValidity() && handleClose();
    PostAsync(
      "Pagos",
      "SetPagos",
      {
        detail: 1,
        semestre: semestre,
        observacion: observacion,
        data: JSON.stringify(data),
      },
      context
    ).then((response) => {
      console.log(response);
      getInit();
      setFormValidated(true);
      setShowModal(false);
      setSelectAll(false);
      setPaying(false);
    });
  };

  const handleCheck = () => {
    // console.log(dataFiltrada);
    let data_ = [];
    let seleccion = selectAll;
    let btnPagar_ = [...btnPagar];
    let totalGeneralFooter = 0;
    let totalIva = 0;
    let totalLineas = [];
    let totalLineasPeso = [];
    dataFiltrada.map((row) => {
      const key = data.findIndex((pro) => pro.label === row.label);
      // console.log(btnPagar);
      totalLineas[row.label] = 0;
      totalLineasPeso[row.label] = 0;
      totalLineasTotal[row.label] = 0;
      row.items.map((row2) => {
        const key2 = data[key].items.findIndex(
          (reg) =>
            reg.op_inventario_items_inventario_id ===
            row2.op_inventario_items_inventario_id
        );
        if (
          data[key].items &&
          data[key].items[key2] &&
          data[key].items[key2].pagar
        ) {
          data[key].items[key2].pagar = seleccion ? false : true;
        } else {
          data[key].items[key2].pagar = seleccion ? false : true;
        }
        totalGeneralFooter += !seleccion
          ? parseFloat(data[key].items[key2].total_restante)
          : 0;
        totalIva += !seleccion ? parseFloat(data[key].items[key2].iva_real) : 0;
        totalLineas[row.label] += !seleccion ? parseInt(row2.cantidad) : 0;
        totalLineasPeso[row.label] += !seleccion ? parseFloat(row2.peso) : 0;
        totalLineasTotal[row.label] += !seleccion
          ? parseFloat(row2.total_restante)
          : 0;
        // console.log(totalIva);
        // row.items[key2].pagar = seleccion ? false : true;
        return (btnPagar_[key][key2].pagar = seleccion ? false : true);
      });
      data_.push(row);
      return row;
    });
    setTotalIva(retentions.aplica_iva == 1 ? totalIva : 0);
    setTotalGeneralFooter(totalGeneralFooter);
    setBtnPagar(btnPagar_);
    setSelectAll(seleccion ? false : true);
    verifyStatus(btnPagar_);
    setTotalLineas(totalLineas);
    setTotalLineasPeso(totalLineasPeso);
    setTotalLineasTotal(totalLineasTotal);
    setBaseRetefuente(
      parseFloat(taxes.base_retefuente.toString().replace(/\./g, ""))
    );
    setReteiva(
      totalGeneralFooter > taxes.base && retentions.aplica_reteiva
        ? totalGeneralFooter * parseFloat(taxes.reteiva)
        : 0
    );
    setReteica(
      totalGeneralFooter > taxes.base_reteica && retentions.aplica_reteiva
        ? totalGeneralFooter * parseFloat(taxes.reteica)
        : 0
    );
    setRetefuente(
      totalGeneralFooter > taxes.base_retefuente && retentions.aplica_retefuente == 1
          ? totalGeneralFooter * parseFloat(taxes.retefuente)
          : 0
    );
    
    setTotalPagar(
      totalGeneralFooter +
        totalIva -
        (retentions.aplica_reteiva && totalGeneralFooter > taxes.base
          ? totalGeneralFooter * parseFloat(taxes.reteiva)
          : 0) -
        (retentions.aplica_reteica && totalGeneralFooter > taxes.base_reteica
          ? totalGeneralFooter * parseFloat(taxes.reteica)
          : 0) -
        retefuente
    );
  };

      console.log("totalGeneralFooter", totalGeneralFooter, taxes.base_retefuente, (totalGeneralFooter > taxes.base_retefuente && retentions.aplica_retefuente == 1), retefuente);

  return (
    <>
      <Modal show={showModal} onHide={handleCloseCross}>
        <Modal.Header closeButton>
          <Modal.Title>Información del pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={formValidated}
            onSubmit={handleSubmitPago}
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Semestre</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setSemestre(e.target.value)}
                  required
                >
                  <option value="">Seleccionar el semestre</option>
                  <option value="1">Primero</option>
                  <option value="2">Segundo</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Porfavor elija un semestre de pago.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Observaciones y comentarios</Form.Label>
              <Form.Control
                style={{ resize: "none", backgroundColor: "#cccccc" }}
                placeholder="Ingrese aquí las observaciones."
                as="textarea"
                onChange={(e) => setObservacion(e.target.value)}
                rows={3}
              />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseCross}>
                Cerrar
              </Button>
              <Button variant="primary" type="submit" disabled={paying} onClick={handleClose}>
                Pagar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="container-fluid" style={{ marginTop: "65px" }}>
        <div className="row justify-content-center mb-3">
          <div className="col-12 h3">
            <b>
              Proveedor {(proveedor.ma_naturaleza_id == 1)?proveedor.nombres + ' ' + proveedor.apellidos:proveedor.nombres}
            </b>
          </div>
          <div className="col mb-3">
            <a
              className="btn btn-primary btn-sm me-10 col-1"
              target="_blank"
              href={
                Config.ConfigApirest +
                "PDF/Pagos/ListaPagosDetallePDF/" +
                params.id +
                "?fecha_inicio=" +
                inputs.fecha_inicio +
                "&fecha_fin=" +
                inputs.fecha_fin +
                "&semana=" +
                inputs.semana +
                "&marca_id=" +
                inputs.marca_id +
                "&producto_id=" +
                inputs.producto_id +
                "&subtotal=" +
                totalGeneralFooter +
                "&iva=" +
                totalIva +
                "&total=" +
                (totalGeneralFooter + totalIva) +
                "&rete_iva=" +
                reteiva +
                "&rete_ica=" +
                reteica +
                "&rete_fuente=" +
                retefuente +
                "&totalPagar=" +
                totalPagar
              }
            >
              <FontAwesomeIcon icon={faPrint} /> PDF
            </a>
            {
              // console.log(dataFiltrada)
            }
          </div>
        </div>
        <div className="row bg-gray">
          <div className="col-12 ">
            <b>Filtros de búsqueda</b>
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
              title="Fecha desde"
              placeholder="Fecha desde"
              type="date"
              name="fecha_inicio"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
              defaultValue={params.fecha_inicio}
            />
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
            <Input
              title="Fecha hasta"
              placeholder="Fecha hasta"
              type="date"
              name="fecha_fin"
              className="form-control"
              onChange={onChangeCustom}
              required={true}
              defaultValue={params.fecha_fin}
            />
          </div>
          <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
            <Select
              id="Semana"
              title="Semana"
              selectDefault="Semana"
              name="semana"
              data={semanas ? semanas : []}
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
            {semanaFilter === "" ? (
              false
            ) : (
              <span className="badge badge-secondary me-1 mb-2">
                {semanaFilter}
                <span
                  className="p-2 cursor-pointer"
                  onClick={() => handleDelete("semana")}
                >
                  X
                </span>
              </span>
            )}
          </div>
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
            <Select
              id="Marca"
              title="Marca"
              selectDefault="marca"
              name="marca_id"
              data={marcas}
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
            {marcaFilter === "" ? (
              false
            ) : (
              <span className="badge badge-secondary me-1 mb-2">
                {marcas.find((brand)=>{
                  return brand.value == marcaFilter
                }).label}
                <span
                  className="p-2 cursor-pointer"
                  onClick={() => handleDelete("marca")}
                >
                  X
                </span>
              </span>
            )}
          </div>
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left">
            <Select
              id="Producto"
              url="/Inventario/ListadoProductos"
              title="Producto"
              selectDefault="Producto"
              name="producto_id"
              data={productos}
              className="form-control"
              onChange={onChangeCustom}
              required={true}
            />
            {opInventarioItemsFilter === "" ||
            opInventarioItemsFilter === null ? (
              false
            ) : (
              <span className="badge badge-secondary me-1 mb-2">
                 {/* {productos[opInventarioItemsFilter - 3].label} */}
                 {productos.find((product)=>{
                  return product.value == opInventarioItemsFilter
                }).label}
                <span
                  className="p-2 cursor-pointer"
                  onClick={() => handleDelete("producto")}
                >
                  X
                </span>
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col">Producto</th>
                  <th className="col">Fecha de compra</th>
                  <th className="col">Semana</th>
                  <th className="col">Marca</th>
                  <th className="col">Observación</th>
                  <th className="col">Cantidad</th>
                  <th className="col">Peso</th>
                  <th className="col">Precio Medida</th>
                  <th className="col">Precio Total</th>
                  <th className="text-center col-2">
                    <div className="row">
                      <div className="col-11 text-end">
                        <div
                          className={
                            selectAll
                              ? "btn btn-primary btn-sm me-2"
                              : "btn btn-secondary btn-sm me-2"
                          }
                          onClick={handleCheck}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                        {parseInt(context.user.tipo_usuario_id) > 3 ||
                        parseInt(context.user.tipo_usuario_id) === 1 ||
                        parseInt(context.user.tipo_usuario_id) === 2 ? (
                          <div
                            className={
                              btn
                                ? "btn btn-primary btn-sm"
                                : "btn btn-secondary btn-sm"
                            }
                            onClick={handleShow}
                          >
                            <FontAwesomeIcon icon={faDollarSign} /> Pagar
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  // console.log(dataFiltrada)
                }
                {dataFiltrada.map((row, key) => {
                  // console.log(row);
                  return (
                    <tr key={key}>
                      <td>{row.label}</td>
                      <td className="text-center">
                        {row.items.map((row2, key2) => {
                          return (
                            <div className="mb-4" key={key2}>
                              {row2.fecha}
                            </div>
                          );
                        })}
                      </td>
                      <td className="text-center">
                        {row.items.map((row2, key2) => {
                          return (
                            <div className="mb-4" key={key2}>
                              {row2.semana}
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        {row.items.map((row2, key2) => {
                          return (
                            <div className="mb-4" key={key2} title={row2.marca}>
                              {row2.marca.length > 15
                                ? row2.marca.substr(0, 15) + "..."
                                : row2.marca}
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        {row.items.map((row2, key2) => {
                          // {console.log(row2)}
                          return (
                            <div className="mb-4" key={key2} title={row2.marca}>
                              {row2.observacion}
                            </div>
                          );
                        })}
                      </td>
                      <td className="text-end">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return (
                              <div className="mb-4 text-white pe-2" key={key2}>
                                {row2.cantidad}
                              </div>
                            );
                          })}
                          <div className="bg-secondary text-white  p-2">
                            <b>{totalLineas[key]}</b>
                          </div>
                        </div>
                      </td>
                      <td className=" text-end ">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return (
                              <div className="mb-4 text-white pe-2" key={key2}>
                                {row2.peso}
                              </div>
                            );
                          })}
                          <div className="bg-secondary text-white  p-2">
                            <b>{format(totalLineasPeso[row.label])}</b>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="text-end">
                          {row.items.map((row2, key2) => {
                            return (
                              <div className="mb-4 pe-2" key={key2}>
                                <div>{format(row2.precio)}</div>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                      <td className="text-end  text-white">
                        <div className="bg-secondary">
                          {row.items.map((row2, key2) => {
                            return (
                              <div className="mb-4 text-white pe-0" key={key2}>
                                <div
                                  className={
                                    row2.pagar === true
                                      ? "btn-primary pe-3"
                                      : "pe-3"
                                  }
                                >
                                  {format(row2.total_restante)}
                                </div>
                              </div>
                            );
                          })}
                          <div className="bg-secondary text-white  p-2">
                            <b>{format(totalLineasTotal[row.label])}</b>
                          </div>
                        </div>
                      </td>
                      <td className="text-center border-start">
                        {row.items.map((row2, key2) => {
                          // console.log(row);
                          return (
                            <div key={key2} className="mb-3">
                              <div
                                className={
                                  row2.pagar === true
                                    ? "btn btn-primary btn-sm me-2"
                                    : "btn btn-secondary btn-sm me-2"
                                }
                                onClick={() =>
                                  selection(
                                    row.label,
                                    row2.op_inventario_items_inventario_id
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faCheck} />
                              </div>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row>
              <Col>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="bg-primary text-white h6">PRODUCTO</td>
                      <td className="bg-primary text-white h6">
                        CANTIDAD TOTAL
                      </td>
                      <td className="bg-primary text-white h6">
                        KILOS TOTALES
                      </td>
                      <td className="bg-primary text-white h6">TOTAL</td>
                    </tr>

                    {data.map((row, key) => {
                      return (
                        <tr key={key}>
                          <td className="bg-primary text-white h6">
                            {row.label}
                          </td>
                          <td>
                            {totalLineas[row.label] === undefined
                              ? 0
                              : totalLineas[row.label]}
                          </td>
                          <td>
                            {totalLineasPeso[row.label] === undefined
                              ? 0
                              : format(totalLineasPeso[row.label])}
                          </td>
                          <td>
                            {totalLineasTotal[row.label] === undefined
                              ? 0
                              : format(totalLineasTotal[row.label])}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
              <Col>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="bg-primary text-white h6">SUBTOTAL</td>
                      <td className="bg-primary text-white text-end h6">
                        {format(totalGeneralFooter)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">IVA</td>
                      <td className="text-end">{format(totalIva)}</td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">TOTAL</td>
                      <td className="text-end">
                        {format(totalGeneralFooter + totalIva)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">RETEIVA</td>
                      <td className="text-end">
                        {totalGeneralFooter > taxes.base &&
                        retentions.aplica_reteiva
                          ? format(totalIva * taxes.reteiva)
                          : format(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">RETEICA</td>
                      <td className="text-end">
                        {totalGeneralFooter > taxes.base_reteica &&
                        retentions.aplica_reteiva
                          ? format(totalGeneralFooter * taxes.reteica)
                          : format(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">RETEFUENTE</td>
                      <td className="text-end">
                        {/* {console.log("aplica rete",retentions.aplica_retefuente, "total", totalGeneralFooter, "base", baseRetefuente, "rete", retefuente)} */}
                        {format(
                          retentions.aplica_retefuente &&
                            totalGeneralFooter > baseRetefuente
                            ? retefuente
                            : 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-primary text-white h6">
                        TOTAL A PAGAR
                      </td>
                      <td className="text-end">
                        {format(
                          totalGeneralFooter +
                            totalIva -
                            (totalGeneralFooter > taxes.base &&
                            retentions.aplica_reteiva
                              ? totalIva * taxes.reteiva
                              : 0) -
                            (retentions.aplica_reteica &&
                            totalGeneralFooter > taxes.base_reteica
                              ? totalGeneralFooter * taxes.reteica
                              : 0) -
                            (retentions.aplica_retefuente &&
                            totalGeneralFooter > baseRetefuente
                              ? retefuente
                              : 0)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default App;
