import React from 'react';
const App=({className,required,defaultValue,type,name,placeholder,onChange,disabled,step,max,id})=>{
  //const [reset, setReset] =   useState(false);
  // useEffect(()=>{
  //   if (reset) {
  //     setReset(false)
  //   }
  // },[reset])
  //
  // useEffect(()=>{
  //   setReset(true)
  // },[defaultValue])

  return <>
            <input
                  id={id}
                  step={step}
                  max={max}
                  className={className}
                  required={required?required:false}
                  defaultValue={defaultValue}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  disabled={disabled?disabled:false}
                  onChange={onChange}
            />
            {placeholder?<div className="form-control-label">{placeholder}</div>:false}
        </>
}

export default App
