import React,{useEffect,useState} from 'react';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
import logo from '../../assets/images/logo.svg';

const defaultValue={
                      nombres:"Alejandro E",
                      apellidos:"Tobón",
                      cedula:"123456789",
                      celular:"3146628042",
                      login:"programandoweb",
                      password:"T3n3rif32021**",
                      direccion:"Carrera 6ta",
                      email:"lic.jorgemendez@gmail.com"
                    }

const App=()=>{
  const context             = React.useContext(StateContext);
  const [inputs, setInputs] = useState(defaultValue);
  const [send, setSend]     = useState(false);

  useEffect(() => {
    context.loading(false)
    if (!send) {
      if (process.env.NODE_ENV==='development') {
        return init()
      }
      setSend(true)
    }
  },[context,send])

  const init=()=>{
    let object={}
    context.constants.Modulos.map((row,key)=>{
      if (row.items) {
        row.items.map((row2,key2)=>{
          if (row2.items!==undefined) {
            row2.items.map((row3,key3)=>{
              return object[row3.label] = JSON.stringify(row3)
            })
            return false
          }else {
            return object[row2.label] = JSON.stringify(row2)
          }
        })
        return object[row.label] = JSON.stringify(row)
      }else {
        return object[row.label] = JSON.stringify(row)
      }

    })
    //Functions.PostAsync("Config","init",object,{})
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let input_= {...inputs}
    PostAsync("Users","CrearCuenta",input_,{},{funct:callback})
  }

  const callback=(data)=>{
    if (data.error!==undefined) {
      context.setModalShow({
        show:true,
        message:  <div className="text-center">
                    <div className="mb-2">{data.error.label}</div>
                    <div className="col ml-auto mr-auto" onClick={()=>context.setModalShow({show:false})}>
                      <div className="btn btn-danger btn-block">Ok</div>
                    </div>
                  </div>,
        size:""
      })
    }else{
      context.setModalShow({
        show:true,
        message:<>  <div className="title-mensage">{data.message.title_message}</div>
                    <div className="text-center w-100 ml-auto mr-auto mb-3">{data.message.label}</div>
                    <div className="col ml-auto mr-auto" onClick={()=>context.setModalShow({show:false})}>
                      <a className="btn btn-danger btn-block" href="./">Continuar</a>
                    </div></>,
        size:""
      })
    }
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }

  return    <>
              {!context.loadingStatus?<>
                <div className={"container-fluid h-100"}>
                      <div className="row h-100">
                        <div className="col-12 col-sm-6 left h-100 d-none d-sm-block">
                          <div className="App-header">
                            <div className="h1 text-white" style={{width: "50%"}}>
                              <img src={logo} alt="programandoweb" style={{width: "100%"}} />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 right h-100">
                          <div className="App-header">
                            <form onSubmit={onSubmit} className="row justify-content-center">
                              <div className="col-9 mb-3 h3 text-center border-bottom  border-danger border-5 pb-2">
                                Registro de usuarios
                              </div>
                              <div className="col-10">
                                <div className="row justify-content-center">
                                  <div className="col-12 col-sm-4 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="nombres"
                                            defaultValue={defaultValue.nombres}
                                            placeholder="Nombres"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-4 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="apellidos"
                                            defaultValue={defaultValue.apellidos}
                                            placeholder="Apellidos"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-4 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="cedula"
                                            defaultValue={defaultValue.cedula}
                                            placeholder="Cédula"
                                            onChange={onChange}
                                    />
                                  </div>
                                </div>
                                <div className="row justify-content-center">
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="celular"
                                            defaultValue={defaultValue.celular}
                                            placeholder="Celular"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="login"
                                            defaultValue={defaultValue.login}
                                            placeholder="Nombre de Usuario"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="email"
                                            defaultValue={defaultValue.email}
                                            placeholder="Email"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="password"
                                            name="password"
                                            defaultValue={defaultValue.password}
                                            placeholder="Clave personal"
                                            onChange={onChange}
                                    />
                                  </div>
                                </div>
                                <div className="row justify-content-center">
                                  <div className="col-12 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="direccion"
                                            defaultValue={defaultValue.direccion}
                                            placeholder="Direcciónn"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 mb-2">
                                    <div className="d-grid gap-2">
                                      <button type="submit" className="btn btn-danger">Registrar</button>
                                    </div>
                                    <a href="./">
                                      Iniciar sesión
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
              </>:false}
            </>



}
export default App
