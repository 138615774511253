import React,{useState} from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import Selector from "../../components/Selector"
import StateContext from '../../helpers/ContextState';
import Config from '../../helpers/Config';

import { faEdit,faFolderOpen,faFileExcel, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
let tds = []
const App=(props)=>{  
  const context                           =   React.useContext(StateContext);  
  const [param, setParam]                 =   useState({tipo_usuario_id: parseInt(localStorage.getItem('userType'))});  
  
  if (param && (param.tipo_usuario_id===3 || param.tipo_usuario_id===4)) {  
    tds       =   [
      {
        label:"#",
        value:"keyUser",
        className:"col font-weight-bold",
        classNameHeader:"col font-weight-bold",
      },
      {
        label:"Nombre",
        value:"label",
        className:"col",
        classNameHeader:"col-3",
      },
      {
        label:"ID o NIT",
        value:"nro_documento",
        className:"col-12 col-sm-1 text-left",
        classNameHeader:"col-2",
      },
      {
        label:"Tipo",
        value:"es_asociado_string",
        className:"col",
        classNameHeader:"col-2",
      },
      {
        label:"Tipo de usuario",
        value:"tipo_usuario_string",
        className:"col",
        classNameHeader:"col-2",
      },
      {
        label:"Acciones",
        value:"events",
        className:"col-1 col-sm-1 text-center",
        classNameHeader:"col-12 text-center",
        icons:[
                {
                  public:true,
                  label:"Editar",
                  NavLink:NavLink,
                  icon: <FontAwesomeIcon icon={faEdit}/>,
                  url:"/Usuarios/Usuario/Edit/"+param.tipo_usuario_id+"/"
                },
                {
                  label:"Descargar información",
                  icon: <FontAwesomeIcon icon="fa-duotone fa-file-excel"/>,
                  url:Config.ConfigApirest+"Excel/Users/ExcelUser/tipoUser:"+param.tipo_usuario_id
                },
                  
              ]
      },
    ]
  }else {
    tds       =   [
      {
        label:"#",
        value:"keyUser",
        className:"col font-weight-bold",
        classNameHeader:"col font-weight-bold",
      },
      {
        label:"Nombre",
        value:"label",
        className:"col-1 col-sm-6 text-left",
        classNameHeader:"col-1 text-left",
      },
      {
        label:"Tipo de usuario",
        value:"tipo_usuario_string",
        className:"col-1 text-left",
        classNameHeader:"col-2",
      },
      {
        label:"Acciones",
        value:"events",
        className:"col-1 col-sm-1 text-center",
        classNameHeader:"col-12 text-center",
        icons:[
                {
                  public:true,
                  label:"Editar",
                  NavLink:NavLink,
                  icon: <FontAwesomeIcon icon={faEdit}/>,
                  url:"/Usuarios/Usuario/Edit/"+param.tipo_usuario_id+"/"
                },
                {
                  label:"Eliminar usuario",
                  icon: <FontAwesomeIcon icon="fa-duotone fa-file-excel"/>,
                  url:Config.ConfigApirest+"Excel/Users/ExcelUser/tipoUser:"+param.tipo_usuario_id
                },
              ]
      },
    ]
  }

  return  <div className="container">
            <div className="row justify-content-center mb-3">
              <div className="col-12">
                
                <Selector 
                    modelo="Maestros"
                    metodo="get_tablas_maestras"
                    param={param}
                    name="tipo_usuario_id"
                    setParam={setParam}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Table
                  filterCustom={true}
                  add={NavLink}
                  td={tds}
                  toExport={Config.ConfigApirest+"Excel/Users/ExcelUser/tipo_usuario_id:"+param.tipo_usuario_id}
                  // toExport={Config.ConfigApirest+"Excel/Users/AllUser/token:"+context.user.token}
                  modelo="Users"
                  metodo="GetUsuarios"
                  limit="5"
                  params={param}
                />
              </div>
            </div>
          </div>
}
export default App
