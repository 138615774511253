import React,{useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";

const App =(props)=>{
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      setReset("ready")
    }
  },[reset])


  useEffect(() => {
    if (props.defaultValue!==undefined && props.defaultValue!=="" && reset!=="ready") {
      setReset(true)
    }
  },[props.defaultValue])

  return  <div className="row pb-2">
            <div className={props.classNameMain!==undefined?props.classNameMain:"col"}>
              {!reset || reset==='ready'?<>
                <select name={props.name}
                    required={(props.required!==undefined && props.required===true)?"required":false}
                    className={props.className + " form-control"}
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}
                    id={props.id}
                    readOnly={(props.readonly!==undefined && props.readonly===true)?"readonly":false}
                    disabled={(props.disabled!==undefined && props.disabled===true)?"disabled":false}
                >
                  {props.selectDefault?<option value="">{props.selectDefault}</option>:false}
                  {props.data!==undefined?<>
                    {props.data.map((row,key)=>{
                      if (props.valuesIguales===undefined) {
                        return <option value={row.value} key={key}>{row.label}</option>
                      }else {
                        return <option value={row.label} key={key}>{row.label}</option>
                      }
                    })}
                  </>:false}
                </select>
              </>:false}
              <div className={props.classNameLabel!==undefined?props.classNameLabel:"form-control-label"}>
                {props.url?<>
                  <NavLink className="form-control-label" to={props.url}>{props.title}</NavLink>
                </>:<>{props.title}</>}
              </div>
            </div>
          </div>
}

export default App
