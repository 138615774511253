import React,{useEffect,useState} from 'react';
import StateContext from '../../helpers/ContextState';
import {Inputs,PostAsync} from '../../helpers/Functions';
import banner from '../../assets/images/banner.jpg';
import logo from '../../assets/images/logo.jpg';
import { useHistory} from "react-router-dom";

const defaultValue={
                      nombres:"Alejandro E",
                      apellidos:"Tobón",
                      cedula:"123456789",
                      celular:"3146628042",
                      login:"programandoweb",
                      password:"T3n3rif32021**",
                      direccion:"Carrera 6ta",
                      email:"lic.jorgemendez@gmail.com"
                    }

const App=()=>{
  let history               =   useHistory();
  const context             =   React.useContext(StateContext);
  const [inputs, setInputs] =   useState(defaultValue);
  const [send, setSend]     =   useState(false);

  useEffect(() => {
    context.loading(false)
    if (!send) {
      if (process.env.NODE_ENV==='development') {
        return init()
      }
      setSend(true)
    }
  },[context,send])

  const init=()=>{
    let object={}
    context.constants.Modulos.map((row,key)=>{
      if (row.items) {
        row.items.map((row2,key2)=>{
          if (row2.items!==undefined) {
            row2.items.map((row3,key3)=>{
              return object[row3.label] = JSON.stringify(row3)
            })
            return false
          }else {
            return object[row2.label] = JSON.stringify(row2)
          }
        })
        return object[row.label] = JSON.stringify(row)
      }else {
        return object[row.label] = JSON.stringify(row)
      }

    })
  }

  const onSubmit=(e)=>{
    e.preventDefault()
    let input_= {...inputs}
    PostAsync("Users","RecoverPassword",input_,{...context}).then((response)=>{
      history.push("./");
    })
  }

  const onChange=(e)=>{
    Inputs(e,inputs, setInputs)
  }

  return    <>
              {!context.loadingStatus?<>
                <div className={"container-fluid h-100"}>
                      <div className="row h-100">
                        <div className="col-12 col-sm-6 h-100 d-none d-sm-block">
                          <div className="App-header">
                            <div className="h1 text-white" >
                              <img src={banner} alt="programandoweb"  className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 right h-100">
                          <div className="App-header">
                            <img src={logo} alt="programandoweb"  />
                            <form onSubmit={onSubmit} className="row justify-content-center">
                              <div className="col-9 mb-3 h3 text-center border-bottom  border-danger border-5 pb-2">
                                Registro de usuarios
                              </div>
                              <div className="col-10">
                                <div className="row justify-content-center">
                                  <div className="col-12 mb-2">
                                    <input  className="form-control"
                                            required={true}
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            onChange={onChange}
                                    />
                                  </div>
                                  <div className="col-12 mb-2">
                                    <div className="d-grid gap-2">
                                      <button type="submit" className="btn btn-danger">Reiniciar contraseña</button>
                                    </div>
                                    <a href="./">
                                      Iniciar sesión
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
              </>:false}
            </>



}
export default App
