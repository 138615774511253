import React, { useState, useEffect } from 'react';
import StateContext from '../../helpers/ContextState';
import { useParams } from "react-router-dom";
import { PostAsync } from '../../helpers/Functions';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Graficos = () => {
    const [comprasCantidad, setComprasCantidad] = useState();
    const [comprasPeso, setComprasPeso] = useState();
    const [ventasCantidad, setVentasCantidad] = useState();
    const [ventasPeso, setVentasPeso] = useState();

    const variablesUrl = useParams();


    useEffect(() => {
        getInit()
    }, [])

    const getInit = () => {

        PostAsync("Inventario", "ResumenInicio", { idProduct: variablesUrl.idProduct }).then((response) => {
            // console.log(response);
            setComprasCantidad(response.comprasCantidad)
            setComprasPeso(response.comprasPeso)
            setVentasCantidad(response.ventasCantidad)
            setVentasPeso(response.ventasPeso)
        })
    }



    // console.log(comprasCantidad);
    return <div className="min-height400 row">
        <div className="row text-center mb-3">
            <h1>{ variablesUrl.nameProduct }</h1>
        </div>
        <div className="row">
            <div className="col-6">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={1500}
                        height={900}
                        data={comprasCantidad}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <text x={350 / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="18">Compras Cantidad</tspan>
                        </text>
                        <Line dataKey="2024" stroke="#8884d8" />
                        <Line dataKey="2025" stroke="#8814d1" />
                        <Line dataKey="Compras Cantidad" stroke="#222222" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="col-6">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={1500}
                        height={900}
                        data={comprasPeso}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <text x={350 / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="18">Compras Peso</tspan>
                        </text>
                        <Line dataKey="2024" stroke="#8884d8" />
                        <Line dataKey="2025" stroke="#8814d1" />
                        <Line dataKey="Compras Peso" stroke="#222222" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                {/* <h1>Ventas Cantidad</h1> */}
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={1500}
                        height={900}
                        data={ventasCantidad}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <text x={350 / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="18">Ventas Cantidad</tspan>
                        </text>
                        <Line dataKey="2024" stroke="#8884d8" />
                        <Line dataKey="2025" stroke="#8814d1" />
                        <Line dataKey="Ventas Cantidad" stroke="#222222" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="col-6">
                {/* <h1>Ventas Peso</h1> */}
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={1500}
                        height={900}
                        data={ventasPeso}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <text x={350 / 2} y={15} fill="black" textAnchor="middle" dominantBaseline="central">
                            <tspan fontSize="18">Ventas Peso</tspan>
                        </text>
                        <Line dataKey="2024" stroke="#8884d8" />
                        <Line dataKey="2025" stroke="#8814d1" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line dataKey="Ventas Peso" stroke="#222222" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
}
export default Graficos;