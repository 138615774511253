const App=({message,event1,event2})=>{
  return    <div className="contentAlert row justify-content-center">
              <div className="col-12 mb-3">
                {message}
              </div>
            <div className="col-4">
              <div className="btn btn-primary col-12" onClick={event1}>
                Continuar
              </div>
            </div>
          </div>
}


export default App
