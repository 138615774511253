import Config from "./Config";
import Store from "./Store";
import ContentContinueModal from '../components/ContentContinueModal';
import * as axios from 'axios';


const REACT_APP_PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY
const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY
const REACT_APP_BACKEND = Config.ConfigApirest

export function format(number) {
  var formatted = new Intl.NumberFormat("es-CO", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
  return formatted;
}

function formatearFecha(fecha, formato) {
  // Convertir la cadena de fecha a un objeto Date
  let partesFecha = fecha.split('-');
  let fechaObjeto = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]);

  // Crear un objeto para manejar los formatos de fecha
  let opcionesFormato = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  // Formatear la fecha según el formato proporcionado
  let fechaFormateada = fechaObjeto.toLocaleString('es-ES', opcionesFormato);  // Ajusta el idioma según tus necesidades

  return fechaFormateada;
}

export const formatoFecha = (fecha, formato) => {
  // const map = {
  //       dd: fecha.getDate()<10?"0"+fecha.getDate():fecha.getDate(),
  //       mm: (fecha.getMonth() + 1)<10?"0"+(fecha.getMonth() + 1):fecha.getMonth() + 1,
  //       yy: fecha.getFullYear()
  //   }

  //   console.log(formato.replace(/dd-mm-yy-yyyy/gi, matched => map[matched]));
  // return formato.replace(/dd-mm-yy-yyyy/gi, matched => map[matched])

  // Crear un objeto para manejar los formatos de fecha
  let opcionesFormato = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  // Formatear la fecha según el formato proporcionado
  let fechaFormateada = fecha.toLocaleString('es-ES', opcionesFormato).replace(/\//g, '-');  // Ajusta el idioma según tus necesidades

  return fechaFormateada;
}

export const capitalizarPrimeraLetra = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const Upload = async (file, save) => {
  const formData = new FormData();
  formData.append('token', Store.get("user").token);
  formData.append('user', Store.get("user").token);
  formData.append('file', file);
  formData.append("PUBLIC_KEY", process.env.REACT_APP_PUBLIC_KEY);
  formData.append("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);
  try {
    const response = await fetch(Config.ConfigApirest + "Upload/Up", {
      method: 'POST',
      body: formData
    });

    const json = await response.json();
    if (json.data !== undefined) {
      return json;
    }
  } catch (error) {
    return error;
  }
}

export const Segment = (num) => {
  let pathname, segment;
  if (num === "all") {
    pathname = window.location.pathname;
    segment = pathname.split('/');
    return segment;
  }
  if (num === undefined) {
    num = 1
  }
  pathname = window.location.pathname;
  segment = pathname.split('/');
  let last = (segment.length - num);
  return segment[last];
}

export const Inputs = (e, inputs, setInputs, mayusculas) => {
  let inputs_ = { ...inputs }

  if (typeof value === 'string' && mayusculas === undefined) {
    inputs_[e.target.name] = e.target.value.toUpperCase()
  } else {
    inputs_[e.target.name] = e.target.value
  }

  try {
    setInputs(inputs_)
  } catch (e) {
    console.log(e);
  }
}

export const IsValidJSONString = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === "object" && item !== null) {
    return true;
  }
  return false;
}

export const PostAsync = async (modulo, m, objeto, context) => {
  let data = new FormData();

  Object.entries(objeto).map((v, k) => {
    return data.append(v[0], v[1]);
  })
  data.append("PUBLIC_KEY", REACT_APP_PUBLIC_KEY)
  data.append("REACT_APP_PRIVATE_KEY", REACT_APP_PRIVATE_KEY)

  if (context !== undefined &&
    context.user !== undefined &&
    context.user.token !== undefined) {
    data.append("user", context.user.token)
    data.append("token", context.user.token)
  }

  try {
    const response = await axios.post(REACT_APP_BACKEND + capitalizarPrimeraLetra(modulo) + "/" + m, data);

    if (!IsValidJSONString(response.data)) {
      console.log("Error en formato JSON, consulte backend");
      return false;
    }

    if (context !== undefined &&
      context.setModalShow !== undefined &&
      response.data.error !== undefined &&
      response.data.error.public === true
    ) {
      context.setModalShow({
        show: true,
        message: <ContentContinueModal message={response.data.error.label} event1={() => context.setModalShow({ show: false })} />,
        size: ""
      }
      )
      return false
    } else if (response.data.error !== undefined && response.data.error.public === false) {
      console.log(response.data.error);
      return false
    } else if (context !== undefined &&
      context.setModalShow !== undefined &&
      response.data.message !== undefined &&
      response.data.message.public === true) {

      context.setModalShow({
        show: true,
        message: <ContentContinueModal message={response.data.message.label} event1={() => {
          context.setModalShow({ show: false })
          // (response.data.message.label === "Datos guardados correctamente" || response.data.message.label === 'Pago realizado con éxito') 
          // && 
          window.location.reload();
        }} />,
        size: ""
      }
      )
    }

    return (response.data !== undefined) ? response.data : response
  } catch (error) {
    //console.log(error);
    return error
  }
}
