import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Table from "../../components/Table"
import SelectorGenerico from '../../components/SelectorGenerico';
import StateContext from '../../helpers/ContextState';
import { PostAsync, formatoFecha, format, Inputs } from '../../helpers/Functions';
import Input from '../../components/InputSimple';
import { useParams } from "react-router-dom";

import SelectorMultiple from "../../components/SelectorMultiple"
import BotonVolverHome from '../../components/BotonVolverHome';
import FormControl from 'react-bootstrap/FormControl'

import Store from '../../helpers/Store'

import { faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import InputGroup from "react-bootstrap/InputGroup";

import Select from "../../components/Select";

let btn_back = false
var previousState = 0
window.addEventListener('popstate', (event) => {
  if (event.state > previousState) {
    btn_back = false
  } else {
    btn_back = true
  }
  previousState = event.state
})

let hoy = formatoFecha(new Date(), "yy-mm-dd");
let paginacion = []


const App = (props) => {

  let limit, limit2 = parseInt(process.env.REACT_APP_NUM_X_PAGE);
  const context = React.useContext(StateContext);
  const [param, setParam] = useState({});
  const [data, setData] = useState([]);
  const [btn, setBtn] = useState(false);
  const [paying, setPaying] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [inputs, setInputs] = useState({});
  const [semanas, setSemanas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [allProviders, setAllProviders] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [inputs2, setInputs2] = useState({});
  const [reset, setReset] = useState(false);
  const [precio_totalizado_final_string, setprecio_totalizado_final_string] = useState("");
  const [cantidad_totalizado_final_string, setcantidad_totalizado_final_string] = useState("");
  const [peso_totalizado_final_string, setpeso_totalizado_final_string] = useState("");
  const [total_rows_empty, setTotal_rows_empty] = useState(true);
  const [open, setOpen] = useState(false);
  const [plantas, setPlantas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [totalizacion, setTotalizacion] = useState({});
  const [paginas, setPaginas] = useState([]);

  const [paginaActual, setPaginaActual] = useState({ label: 1, start: 0 });
  const params_url = useParams();

  const [showModal, setShowModal] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [semestre, setSemestre] = useState("");
  const [observacion, setObservacion] = useState("");

  let filter = "";
  useEffect(() => {
    basicInputs()
  }, [])

  useEffect(() => {
    getInit()
  }, [search])

  useEffect(() => {
    if (param.ma_periodicidad_id) {
      getInit()
    } else {
      setData([])
      setTotal(0)
    }
  }, [param])

  const handleCloseCross = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getInit()
    // const { proveedor_id } = inputs2; // Destructuring para obtener el array de proveedores
    // const selected = proveedor_id;
    // let providers = proveedores.filter((provider)=>{
    //   return  !selected.some(selected_provider => selected_provider.value == provider.value)
    // })

    // allProviders.forEach(old => {
    //   if (!selected.some(selected_provider => selected_provider.value == old.value)) {
    //     providers.push(old); // Change here to push the entire old provider object
    //   }
    // });
    // setProveedores(providers)
    // console.log("allProviders", allProviders)
    // console.log("provi", providers)
    // console.log("selected", selected)
  }, [inputs, inputs2])

  const getInit = (obj) => {
    //console.log(obj);
    setData([])
    let data_ = { ...props }
    if (props.params && typeof props.params === "object") {
      Object.entries(props.params).map((row, key) => {
        return data_[row[0]] = row[1]
      })
      delete (data_.params);
    }

    delete (data_.td);
    data_.fecha = hoy;
    data_.limit = process.env.REACT_APP_NUM_X_PAGE
    data_.start = obj ? obj.paginaActual.start : paginaActual.start
    if (filter !== '') {
      data_.filter = filter
    }
    let parameters_send = { ...data_, ...inputs, filtroMultiple: JSON.stringify(inputs2), ...params_url, active: true }
    let parameters_save = { data: data_, inputs: inputs, inputs2: inputs2, params_url: params_url, active: true }

    if (!btn_back || (btn_back && !Store.get(window.location.pathname).active)) {
      //console.log("si llena");
      Store.set(window.location.pathname, parameters_send)
      Store.set(window.location.pathname + "save", parameters_save)
    } else if (btn_back && Store.get(window.location.pathname).active) {
      //console.log("llena del store",Store.get(window.location.pathname));
      parameters_send = Store.get(window.location.pathname)
      parameters_save = Store.get(window.location.pathname + "save")
      btn_back = false
      setInputs(parameters_save.inputs)
      setInputs2(parameters_save.inputs2)
      setReset(true)
      setTimeout(function () {
        setReset(false)
      }, 1000);
    }
    // Store.set(window.location.pathname,{...data_,...inputs,filtroMultiple:JSON.stringify(inputs2),...params_url})

    PostAsync("Pagos", "ListadoPagos", { ...inputs, ...param, filtroMultiple: JSON.stringify(inputs2), search: search }, context).then((response) => {
      setprecio_totalizado_final_string(response.precio_totalizado_final_string)
      setcantidad_totalizado_final_string(response.cantidad_totalizado_final_string)
      // setpeso_totalizado_final_string(response.peso_totalizado_final_string)
      // console.log(response.totalizador);
      //return setOpen(true);
      if (response === undefined || response.data === undefined) {
        return false;
      }

      if (response.data && (response.data.total_rows === undefined || response.data.total_rows === '0')) {
        setData([])
        setTotal_rows_empty(false)
        setOpen(true)
      }

      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      if (response.data && response.data.plantas) {
        setPlantas(response.data.plantas)
      }

      if (response.data && response.data.proveedores) {
        // setAllProviders(response.data.proveedores)
        setProveedores(response.data.proveedores)
      }
      // console.log("allProviders", allProviders);
      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }

      if (response.data && response.data.clientes) {
        setClientes(response.data.clientes)
      }


      if (response.data && response.data.productos) {
        setProductos(response.data.productos)
      }

      if (response.totalizador) {
        setTotalizacion(response.totalizador)
      }

      let trows = (response.data.total_rows !== undefined) ? parseInt(response.data.total_rows) : 0;
      limit = parseInt(process.env.REACT_APP_NUM_X_PAGE)
      paginacion = Math.ceil(trows / process.env.REACT_APP_NUM_X_PAGE)
      // const uniqueRows = response.data.rows.reduce((acc, current) => {
      //   const x = acc.find(item => item.id_or_nit === current.id_or_nit && item.op_inventario_items_inventario_id === current.op_inventario_items_inventario_id && item.total_deuda_actual === current.total_deuda_actual && item.fecha_minima === current.fecha_minima);
      //   if (!x) {
      //     return acc.concat([current]);
      //   } else {
      //     return acc;
      //   }
      // }, []);
      // setData(uniqueRows);
      setData(response.data.rows);
      
      if (response.data && response.data.total_rows && response.data.total_rows !== "0") {
        //setTotal_rows_empty(false)
        setTotal_rows_empty(response.data.total_rows)
      }

      /*cambio*/
      let pages = []
      let ultimo = 0
      for (var i = 0; i < paginacion; i++) {
        ultimo = limit2 * (i)
        pages.push({ label: i + 1, start: ultimo })
      }
      setPaginas(pages)

      setOpen(true)
    })
  }

  // const getInit=()=>{
  //   setData([])
  //   setTotal(0)



  //   let parameters_send         =   {inputs:inputs,param:param,filtroMultiple:JSON.stringify(inputs2),search:search,active:true}
  //   let parameters_save         =   {inputs:inputs,param:param,inputs2:inputs2,active:true,search:search,}


  //   if (!btn_back || (btn_back && !Store.get(window.location.pathname).active)) {
  //     Store.set(window.location.pathname,parameters_send)
  //     Store.set(window.location.pathname+"save",parameters_save)

  //   }else if (btn_back && Store.get(window.location.pathname).active) {

  //     parameters_send       =   Store.get(window.location.pathname)
  //     parameters_save       =   Store.get(window.location.pathname+"save")
  //     btn_back              =   false

  //     setParam(parameters_save.param)
  //     setInputs(parameters_save.inputs)
  //     setInputs2(parameters_save.inputs2)
  //     setReset(true)
  //     setTimeout(function(){
  //       setReset(false)
  //     }, 1000);
  //   }


  //   PostAsync("Pagos","ListadoPagos",{...inputs,...param,filtroMultiple:JSON.stringify(inputs2),search:search},context).then((response)=>{
  //     if (response.data && response.data.rows) {
  //       let pagar_    =   []
  //       let total_    =   0
  //       /*voy a colocar los inputs activos por defecto para hacer los pagos*/
  //       response.data.rows.map((row,key)=>{
  //         total_  +=  parseFloat(row.total_deuda_actual)
  //         pagar_.push({...row,uid:row.token,key:key,pagar:true})
  //       })
  //       setTotal(total_)
  //       setData(pagar_)
  //       setBtn(true)
  //     }
  //   })
  // }

  const basicInputs = () => {
    PostAsync("Inventario", "ListadoCompras", {
      ...param,
      inTrash: 1,
      fecha: "0000-00-00",
      limit: 0,
      start: 0
    }, context).then((response) => {

      if (response.data && response.data.semanas) {
        setSemanas(response.data.semanas)
      }

      //console.log(response.data);
      if (response.data && response.data.marcas) {
        setMarcas(response.data.marcas)
      }

      if (response.data && response.data.proveedores) {
        let uniqueProviders = [...new Set(response.data.proveedores.map(provider => JSON.stringify(provider)))].map(provider => JSON.parse(provider));
        setAllProviders(uniqueProviders);
        console.log("response.data.proveedores",allProviders)
        if(context.user.tipo_usuario_id == 3){
          setProveedores(response.data.proveedores.filter(proveedor => proveedor.label == context.user.nombres + " " + context.user.apellidos))
        }else{
          setProveedores(response.data.proveedores)
        }
        // setProveedores(response.data.proveedores)
      }

    })
  }

  const selection = (row, key) => {
    let data_ = [...data]
    data_[key].pagar = (data_[key].pagar === true) ? false : true;
    setData(data_)
    if (checkActive()) {
      setBtn(true)
    } else {
      setBtn(false)
    }

    let checkedItems = data_.filter((elem) => elem.pagar)
    data.length === checkedItems.length ? setSelectAll(true) : setSelectAll(false)
  }

  const handleSubmitPagar = (event) => {
    setPaying(true);
    event.preventDefault();
    event.stopPropagation();
    if (checkActive()) {
      PostAsync("Pagos", "SetPagos", {
        semestre: semestre,
        observacion: observacion,
        data: JSON.stringify(data)
      },
      context).then((response) => {
        getInit();
        setShowModal(false);
        setSelectAll(false)
        setPaying(false);
        })
    }
  }

  const handleShow = () => {
    checkActive() && setShowModal(true);
  };

  const handleClose = () => {
    !checkActive() && setShowModal(false);
  };

  const checkActive = () => {
    const boolean = data.find(search => search.pagar === true)
    return (boolean !== undefined) ? true : false
  }

  const onChangeCustom = (e) => {
    Inputs(e, inputs, setInputs)
  }

  const handleCheck = () => {
    let data_ = []
    let seleccion = selectAll

    data.map((row, key) => {
      row.pagar = seleccion ? false : true
      data_.push(row)
    })
    setData(data_)
    setSelectAll((seleccion) ? false : true)
  }
  // console.log(context.user.tipo_usuario_id);
  return (<>
    <Modal show={showModal} onHide={handleCloseCross}>
      <Modal.Header closeButton>
        <Modal.Title>Información del pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={formValidated}
          onSubmit={handleSubmitPagar}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Semestre</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setSemestre(e.target.value)}
                required
              >
                <option value="">Seleccionar el semestre</option>
                <option value="1">Primero</option>
                <option value="2">Segundo</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Porfavor elija un semestre de pago.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Observaciones y comentarios</Form.Label>
            <Form.Control
              style={{ resize: "none", backgroundColor: "#cccccc" }}
              placeholder="Ingrese aquí las observaciones."
              as="textarea"
              onChange={(e) => setObservacion(e.target.value)}
              rows={3}
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCross}>
              Cerrar
            </Button>
            <Button variant="primary" disabled={paying} type="submit" onClick={handleClose}>
              Pagar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal><div className="container">
      {/* {console.log("tipouserid",context.user.tipo_usuario_id)} */}
      {parseInt(context.user.tipo_usuario_id) > 3  || parseInt(context.user.tipo_usuario_id) === 1  || parseInt(context.user.tipo_usuario_id) === 2 ?
        <div className="row justify-content-center mb-3">
          <div className="col-12">
            <BotonVolverHome />
            <SelectorGenerico modelo="Maestros"
              metodo="get_tablas_maestras"
              tabla="ma_periodicidad"
              param={param}
              lg="3"
              name="ma_periodicidad_id"
              setParam={setParam} />
          </div>
        </div> : false}
      {/* <div className="row bg-gray">
                           
            </div> */}
      <div className="row bg-gray">
        <b>Filtros de búsqueda en pagos a realizar</b>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha desde"
            placeholder="Fecha desde"
            type="date"
            name="fecha_inicio"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left">
          <Input
            title="Fecha hasta"
            placeholder="Fecha hasta"
            type="date"
            name="fecha_fin"
            className="form-control"
            onChange={onChangeCustom}
            required={true}
          />
        </div>
        <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
          <SelectorMultiple
            name="semana"
            title="Semana"
            selectDefault="Semana"
            data={semanas}
            inputs={inputs2}
            setInputs={setInputs2}
            className="form-control"
          />
        </div>
        <div className="col-3">
          <FormControl
            defaultValue={filter}
            name="filter"
            onChange={onChangeCustom}
            placeholder="Buscador ID o NIT"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <br />
        </div>
        {/* <div className="col-12 col-sm-2 mb-3 pt-1 text-left border-end">
                <SelectorMultiple
                    url="/Inventario/ListadoMarcas"
                    name="marca_id"
                    title="Marca"
                    selectDefault="Marca"
                    data={marcas}
                    inputs={inputs2}
                    setInputs={setInputs2}
                    className="form-control"
                />
              </div> */}
        {parseInt(context.user.tipo_usuario_id) <= 6  ?
          <div className="col-12 col-sm-3 mb-3 pt-1 text-left border-end">  
            <SelectorMultiple
              url="/Usuarios/Lista"
              name="proveedor_id"
              title="Proveedor"
              selectDefault="Proveedor"
              data={proveedores}
              inputs={inputs2}
              setInputs={setInputs2}
              className="form-control"
            />
          </div> : false}
      </div>
      <div className="row">
        <div className="col">
          <table className="table hover">
            <thead>
              <tr>
                <th className="col">
                  Fecha inicial
                </th>
                <th className="col">
                  Fecha final
                </th>
                <th className="col">
                  Semana
                </th>
                <th className="col">
                  ID o NIT
                </th>
                <th className="col">
                  Correo contacto
                </th>
                <th className="col">
                  Teléfono contacto
                </th>
                <th className="col">
                  Dirección
                </th>
                <th className="col">
                  Proveedor
                </th>
                <th className="col">
                  SubTotal
                </th>
                <th className="col-2 text-end">
                  Total Adeudado
                </th>
                <th className="text-center col-3">
                  {data.length > 0 &&
                    <div className="row">
                      {parseInt(context.user.tipo_usuario_id) > 3  || parseInt(context.user.tipo_usuario_id) === 1  || parseInt(context.user.tipo_usuario_id) === 2  ?
                        <div className="col-11 text-end">
                          <div className={selectAll ? "btn btn-primary btn-sm me-2" : "btn btn-secondary btn-sm me-2"} onClick={handleCheck}>
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                          <div className={btn ? "btn btn-primary btn-sm" : "btn btn-secondary btn-sm"} onClick={handleShow}>
                            <FontAwesomeIcon icon={faDollarSign} /> Pagar seleccionado
                          </div>
                        </div> : false}
                    </div>}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.length === 0 ?
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      Sin resultados...
                    </td>
                    <td></td>
                  </tr> :
                  data.map((row, key) => {
                    return <tr key={key}>
                      <td>
                        {row.fecha_minima}
                      </td>
                      <td>
                        {row.fecha_maxima}
                      </td>
                      <td>
                        {row.semana}
                      </td>
                      <td>
                        {row.id_or_nit}
                      </td>
                      <td>
                        {row.email}
                      </td>
                      <td>
                        {row.celular}
                      </td>
                      <td>
                        {row.direccion}
                      </td>
                      <td>
                        {row.proveedor}
                      </td>
                      <td>
                        {format(row.total_restante)}
                      </td>
                      <td className="text-end">
                        {format(row.deuda_provedor)}
                      </td>
                      <td className="text-center border-start">
                        {parseInt(context.user.tipo_usuario_id) <=6  ?
                          <div className={row.pagar === true ? "btn btn-primary btn-sm me-2" : "btn btn-secondary btn-sm me-2"} onClick={() => selection(row, key)}                                        >
                            <FontAwesomeIcon icon={faCheck} />
                          </div> : false}
                        <NavLink to={"/Pagos/Lista/Detalle/" + row.token + "/" + row.fecha_minima + "/" + row.fecha_maxima} className="btn btn-warning btn-sm">Pago detallado</NavLink>
                      </td>
                    </tr>
                  })
              }
            </tbody>
            <tfoot>
              <tr className='bg-primary '>
                <td>

                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {
                  data.length === 0 ? <td className="text-end text-white">

                  </td> : <td className="text-end text-white">
                    {format(totalizacion)}
                  </td>
                }
                <td>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </>
  )
}
export default App
