import React,{useState,useEffect} from 'react';
// import Select from "./Select"
import Select from 'react-select';
import { NavLink } from "react-router-dom";
const App=({name,title,inputs,setInputs,data,selectDefault,url})=>{
  const [selects, setSelects] = useState([]);

  // const onChangeCustom=(e)=>{
  //   if (e.target.value==='') {
  //     return false
  //   }
  //   let languages = document.getElementById(e.target.name);
  //   let string    = ""
  //   if (languages) {
  //     string=languages.selectedOptions[0].text
  //   }
  //   let selection = {...inputs}
  //   if (!selection[name]) {
  //     selection[name] = []
  //   }
  //   // Validate if the value already exists before pushing
  //   if (!selection[name].some(item => item.value === e.target.value)) {
  //     selection[name].push({
  //       name:name,
  //       value:e.target.value,
  //       label:string,
  //     })
  //   }
  //   setInputs(selection)
  //   e.target.value=''    
  // }
  // const onChangeCustom = (selectedOptions) => {
  //   if (!selectedOptions || selectedOptions.length === 0) {
  //       return false;
  //   }
  //   let selection = { ...inputs };
  //   if (!selection[name]) {
  //       selection[name] = [];
  //   }
  //   // Validate if the value already exists before pushing
  //   selectedOptions.forEach(option => {
  //       if (!selection[name].some(item => item.value === option.value)) {
  //           selection[name].push({
  //               name: name,
  //               value: option.value,
  //               label: option.label,
  //           });
  //       }
  //   });
  //   setInputs(selection);
  // }
  const onChangeCustom = (selectedOptions) => {
    let selection = { ...inputs };
    if (!selection[name]) {
        selection[name] = [];
    }

    // Eliminar opciones que ya no están seleccionadas
    const currentValues = selection[name].map(item => item.value);
    const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];

    // Filtrar las opciones que ya no están seleccionadas
    selection[name] = selection[name].filter(item => newValues.includes(item.value));

    // Agregar nuevas opciones seleccionadas
    selectedOptions.forEach(option => {
        if (!currentValues.includes(option.value)) {
            selection[name].push({
                name: name,
                value: option.value,
                label: option.label,
            });
        }
    });

    setInputs(selection);
}

  // const handleDelete=(row_)=>{
  //   let inputs_ = []
  //   if (inputs[name] && inputs[name].length>0) {
  //     inputs[name].map((row,key)=>{
  //       if (row.value!==row_.value) {
  //         return inputs_.push(row)
  //       }
  //     })
  //   }
  //   setInputs({...inputs,[name]:inputs_})
  // }

  return  <div className="row">
            <div className="col-12">
              {/* <Select
                  selectDefault={selectDefault}
                  name={name}
                  data={data}
                  id={name}
                  className="form-control"
                  onChange={onChangeCustom}
              /> */}
              <Select
              defaultValue={selectDefault}
              isMulti
              name={name}
              options={data}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={onChangeCustom}
            />
             <span><b style={{fontSize: 'small'}} >{title}</b></span>
            </div>
            {/* <div className="col">
              <div className="form-control-label">
                {inputs[name] && inputs[name].length>0?<>
                  <span className="me-2">{title}</span>
                  {inputs[name].map((row,key)=>{
                    return  <span key={key} className="badge badge-secondary me-1 mb-2">
                              {row.label} <span className="p-2 cursor-pointer" onClick={()=>handleDelete(row)}>X</span>
                            </span>
                  })}
                </>:url!==undefined?<NavLink className="form-control-label" to={url}>{title}</NavLink>:title}
              </div>
            </div> */}
          </div>
}
export default App
