import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {PostAsync} from '../../helpers/Functions';
import { useParams  } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import { NavLink } from "react-router-dom";

const App=()=>{

  const params              =   useParams();
  const context             =   React.useContext(StateContext);
  const [data, setData]     =   useState({open:false});
  console.log(params)
  const onChange=(row)=>{
    let send            = {...params}
        send.modulo     = row.url
    let data_           = {...data}
        if (data_[row.url]!==undefined) {
          delete(data_[row.url])
        }else {
          data_[row.url]  = 1
        }
        setData(data_)
    PostAsync("Users","SetUsuarioPrivilegios",send,{...context}).then((response) => {

      return false
    })
  }

  useEffect(() => {
    let mounted = true
    if (params.id!==0) {
      getInit(mounted)
    }
    return function cleanup() {
      mounted = false
    }
  },[])

  const getInit=(mounted,uid)=>{

    PostAsync("Users","GetUsuarioPrivilegios",{tuid:params.id},{...context}).then((response) => {
      
      if (response.data!==undefined && mounted) {
        setData({...response.data,open:true})
      }
    })
  }

  return  <div className={data && data.open?"col-12":"d-none"} style={{marginTop: '65px'}}>
            <h3 style={{ marginLeft: '1.5rem', marginBottom: '2%', fontSize: '1.8rem' }}>{params.usuario}</h3>
            <Tabs defaultActiveKey={0} id="uncontrolled-tab-example" className="mb-3">
              {context.constants.Modulos.map((row,key)=>{
                return  <Tab eventKey={key} title={row.label} key={key}>
                          <Row>
                            {row.items===undefined?<>
                              <Col>
                                <div className={data[row.url]!==undefined?"btn btn-primary":"btn btn-secondary"}
                                      onClick={()=>onChange(row)}>
                                    {row.label}
                                </div>
                              </Col>
                            </>:row.items!==undefined?<>
                              <Col>
                                <div className={data[row.url]!==undefined?"btn btn-primary":"btn btn-secondary"}
                                      onClick={()=>onChange(row)}>
                                    {row.label}
                                </div>
                              </Col>
                              {row.items.map((row2,key2)=>{
                                return    <Col>
                                            <div className={data[row2.url]!==undefined?"btn btn-primary":"btn btn-secondary"}
                                                  onClick={()=>onChange(row2)}>
                                                  {row2.label}
                                            </div>
                                          </Col>
                              })}
                            </>:false}
                          </Row>
                        </Tab>
              })}
            </Tabs>
            <div className="mt-3">
              <NavLink className="btn btn-secondary" to={"/Usuarios/TiposDeUsuarios"}>
                Cerrar
              </NavLink>
            </div>
          </div>
}
export default App
