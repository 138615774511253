import React,{useState,useEffect} from 'react';
import {PostAsync} from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


const App=({id,uid})=>{

  const context             =   React.useContext(StateContext);
  const [data, setData]     =   useState([]);
  //const [inputs, setInputs] =   useState({nombres:""});

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    return function cleanup() {
      mounted = false
    }
  },[])

  const getInit=(mounted)=>{
    PostAsync("Users","GetUsuarioMarcas",{uid:uid},{...context}).then((response) => {
      if (response.data && response.data!==null) {
        setData(response.data)
      }
    })
  }

  // const onChange=(e)=>{
  //   Inputs(e,inputs, setInputs)
  // }
  return  <>
            <Row>
              <Col>
                <div>
                  <Table responsive="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Marca</th>
                        <th>Planta</th>
                        <th width="120">Fecha desde</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length===0?<>
                          <tr>
                            <td colSpan="4" className="text-center">
                              No hay registros
                            </td>
                          </tr>
                        </>:<>
                        {data.map((row,key)=>{
                          return  <tr key={key}>
                                    <td width="20">
                                      {key+1}
                                    </td>
                                    <td width="10%">
                                      {row.marca}
                                    </td>
                                    <td >
                                      {row.label}
                                    </td>
                                    <td >
                                      {row.fecha_actualizacion}
                                    </td>
                                </tr>
                        })}
                      </>}

                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </>
}

export default App
