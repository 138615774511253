import React, { useState, useEffect } from 'react';
import { PostAsync } from '../helpers/Functions';
import StateContext from '../helpers/ContextState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


const ProductosComprados = ({ id, uid }) => {

  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState({ nombres: "" });

  useEffect(() => {
    let mounted = true
    getInit(mounted)
    return function cleanup() {
      mounted = false
    }
  }, [])

  const getInit = (mounted) => {
    PostAsync("Users", "GetUsuarioPropductosComprados", { uid: uid }, { ...context }).then((response) => {
      if (response.data && response.data !== null) {
        setData(response.data)
        // console.log(response.data);
      }
    })
  }
  let isOddProduct = false;
  return <>
    <Row>
      <Col>
        <div>
          <Table responsive="sm"> 
            <thead>
              <tr>
                <th>Productos</th>
                <th>Marca</th>
                <th>Planta</th>
                <th>Fecha recibido</th>
                <th>Hora</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? <>
                <tr>
                  <td colSpan="6" className="text-center">
                    No hay registros
                  </td>
                </tr>

              </> : <>
                {Object.keys(data).map(producto => {
                  const productColorClass = isOddProduct ? '' : 'bg-secondary text-white';
                  isOddProduct = !isOddProduct;

                  return (
                    data[producto].map((item, index) => (
                      <tr key={index} className={productColorClass}>
                        {index === 0 ? (
                          <td rowSpan={data[producto].length}>{producto}</td>
                        ) : null}
                        <td>{item.marca.split(' ')[0]}</td>
                        <td>{item.planta}</td>
                        <td>{item.fecha_recibido}</td>
                        <td>{item.hora}</td>
                      </tr>
                    ))
                  );
                })}
              </>}

            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </>
}

export default ProductosComprados
