import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types';
import {PostAsync} from '../helpers/Functions';
import StateContext from '../helpers/ContextState';

const App=({param,modelo,metodo,name,setParam})=>{
  const context             =   React.useContext(StateContext);
  const [tipos, setTipos]   =   useState([]);


  useEffect(() => {
    if (tipos.length===0) {
      PostAsync(modelo,metodo,{tabla:"ma_tipo_usuarios"},{...context}).then((response)=>{
        if (response.data!==undefined) {
          setTipos(response.data)
        }
      })
    }
  },[context,tipos])

  const handleChange=(row)=>{

    localStorage.setItem('userType', row.value);
    if (setParam) {
      let param_        = {...param}
          param_[name]  = typeof parseInt(row.value) === "number"?parseInt(row.value):row.value
          setParam(param_)

          if (typeof parseInt(row.value) === "number" && tipos) {
            let  result  =    tipos.find( buscar => (buscar.value === row.value ))

            if (result) {
              context.setExtraPosition(" / "+result.label)
            }else {
              context.setExtraPosition("")
            }

          }

    }
  }

  return  <Row>
            {tipos?<>
              {tipos.map((row,key)=>{
                return  <Col  key={key}
                              xs="12"
                              lg="2"
                        >
                          <div className="d-grid gap-2">
                            <Button variant={param[name]===parseInt(row.value)?"primary":"secondary" }
                              onClick={()=>handleChange(row)}
                              >
                              {row.label}
                            </Button>
                          </div>
                        </Col>
              })}
            </>:false}
          </Row>
}

App.propTypes = {
  name: PropTypes.string.isRequired,
  modelo: PropTypes.string.isRequired,
  metodo: PropTypes.string.isRequired,
  param: PropTypes.object.isRequired,
  setParam: PropTypes.func.isRequired,
}

App.defaultProps = {
  name: "estatus",
  modelo: "",
  metodo: "",
  param: {estatus:0},
  setParam: ()=>{return false},
}

export default App
