import React from 'react';
import StateContext from '../helpers/ContextState';
import { Link } from "react-router-dom";
const BotonVolverHome=()=>{
  const context     =   React.useContext(StateContext);
  return  <div className="p-2 text-end">
              <div className="btn btn-primary btn-sm">
                <Link to="/" className='link'>
                  Home
                </Link>
              </div>
            
          </div>
}
export default BotonVolverHome
