import React, { useState } from 'react';
import Input from '../../components/Inputs';
import StateContext from '../../helpers/ContextState';
import { PostAsync } from '../../helpers/Functions';
import SelectorGenerico from '../../components/SelectorGenerico';
import Button from 'react-bootstrap/Button';


const AgregarProducto = () => {

    const context = React.useContext(StateContext);
    const [param, setParam] = useState({});
    const [variante, setVariante] = useState({
        varianteUnidad: 'secondary',
        varianteKgr: 'secondary'
    });

    const producto = {
        medida: '',
        producto: '',
        precioAsociado: '',
        precioNoAsociado: '',
        iva: '',
        observacion: '',
        cod_producto_venta: '',
        cod_producto_compra: '',
    }


    const [dataProduct, setDataProduct] = useState(producto);

    const handleMedida = (medida) => {
        if (medida == 1) {
            setVariante({
                varianteUnidad: 'secondary',
                varianteKgr: 'primary'
            }
            )
        } else if (medida == 2) {
            setVariante({
                varianteUnidad: 'primary',
                varianteKgr: 'secondary'
            }
            )
        }
        setDataProduct({
            ...dataProduct,
            medida: medida
        })
    }

    const createProduct = (e) => {
        e.preventDefault();
        // console.log(dataProduct);
        PostAsync('Inventario', 'createProduct', dataProduct).then((response) => {
            setDataProduct(producto);
            if (response.label == "Producto creado correctamente!") {
                context.setModalShow({
                    show: true,
                    size: "sm",
                    message:  <div className="text-center">
                    <div className="mb-3">{response.label}</div>
                    <div
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      Cerrar
                    </div>
                  </div>
                })
            }

        })
    }

    return <div className="container" style={{ marginTop: '55px' }}>
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={createProduct}>
                            <div className="h3"><b>Agregar producto</b> <div className='display-6'>
                            </div></div>
                            <div className="row pb-2">
                                <div className="col-12 col-sm-6 mb-2">
                                    <div className="h4">Unidad de medida:</div>
                                    <div className='row'>
                                        <Button className='col' style={{ margin: '20px' }} variant={variante.varianteUnidad} onClick={() => handleMedida(2)}>Unidad</Button>
                                        <Button className='col' style={{ margin: '20px' }} variant={variante.varianteKgr} onClick={() => handleMedida(1)}>Kgr</Button>
                                    </div>
                                    {console.log(dataProduct)}

                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-12 col-sm-2 mb-2">
                                    <Input
                                        required
                                        title="Producto"
                                        placeholder="Producto"
                                        type="text"
                                        name="producto"
                                        className="form-control"
                                        onChange={(e) => setDataProduct({ ...dataProduct, producto: e.target.value })}
                                    />
                                </div>
                                <div className="col-13 col-sm-2 mb-2">
                                    <Input
                                        required
                                        title="Precio asociado"
                                        placeholder="Precio asociado"
                                        type="number"
                                        name="precio_asociado"
                                        className="form-control"
                                        onChange={(e) => setDataProduct({ ...dataProduct, precioAsociado: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 mb-2">
                                    <Input
                                        required
                                        title="Precio no asociado"
                                        placeholder="Precio no asociado"
                                        type="number"
                                        name="precio_no_asociado"
                                        className="form-control"
                                        onChange={(e) => setDataProduct({ ...dataProduct, precioNoAsociado: e.target.value })}
                                    />
                                </div>
                                <div className="col-12 col-sm-3 mb-2">
                                    <Input
                                        title="IVA"
                                        placeholder="IVA"
                                        type="number"
                                        name="iva"
                                        step="0.1"
                                        className="form-control"
                                        onChange={(e) => setDataProduct({ ...dataProduct, iva: e.target.value })}
                                    />
                                </div>
                                <div className="row pb-2">
                                    <div className="col-12 col-sm-3 mb-2">
                                        <Input
                                            required
                                            title="Codigo de Producto (VENTA)"
                                            placeholder="Codigo de Producto (VENTA)"
                                            type="text"
                                            name="cod_producto_venta"
                                            className="form-control"
                                            onChange={(e) => setDataProduct({ ...dataProduct, cod_producto_venta: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-3 mb-2">
                                        <Input
                                            required
                                            title="Codigo de Producto (COMPRA)"
                                            placeholder="Codigo de Producto (COMPRA)"
                                            type="text"
                                            name="cod_producto_compra"
                                            className="form-control"
                                            onChange={(e) => setDataProduct({ ...dataProduct, cod_producto_compra: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                <div className="col-12 mb-2">
                                    <Input
                                        title="Observación"
                                        placeholder="Observación"
                                        type="text"
                                        name="observacion"
                                        className="form-control"
                                        onChange={(e) => setDataProduct({ ...dataProduct, observacion: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-center pb-2">
                                <div className="col-12 col-sm-2 mb-2">
                                    <span type="sucesfull" message="Producto agregado correctamente" />
                                    <button type="submit" className="btn btn-primary col-12">Agregar</button>
                                </div>

                                <div className="col-12 col-sm-2 mb-2">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

}
export default AgregarProducto